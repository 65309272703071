<template>
  <div class="navbarmobileFix">
  <div>

    <nav class="d-none d-lg-block three-nav1">
      <ul class="three-nav1 navigation-list-item-blk" :class="{ 'show-navigation-list': seen }">

        <li class="three-nav1" @click="searchClinic()">
          <div>
            <img src="~@/assets/images/hospitallens.png" style="width: 40px;">
          </div>
        </li>
        <!-- <li class="three-nav1" @click="requestConsultation()">
          <div>
            <img src="~@/assets/images/hospitalplus.png" style="width: 40px;">
          </div>
        </li> -->
        <li class="three-nav1" @click="joinDoctorsCommunity()">
          <div>
            <img src="~@/assets/images/man-man.png" style="width: 40px;">
          </div>
        </li>
        <li class="three-nav1" @click="joinPatientGroups()">
          <div>
            <img src="~@/assets/images/community.png" style="width: 40px;">
          </div>
        </li>
      </ul>
    </nav>

    <section class="banner-two2 img banner">
      <div class="container-fluid text-center text-align">
        <div class="heading-information">
          <h1>Access reliable information</h1>
        </div>
          <!-- FOR DESKTOP  -->
        <div class="banner-sub-content d-none d-lg-block accesinformationheadingText">
          
          <h3 class="mb-0">One of our objectives is to enhance health literacy levels.</h3>
          <!-- <div> -->
          <h3 class="m-4 mb-0">In line with this objective, access reliable and easy-to-read patient </h3>
          <h3 class="mb-0"> resource for free. These resources are created and reviewed by doctors </h3> 
          <h3 class="mb-0"> and healthcare professionals. We will be adding more </h3> 
          <h3 class="mb-0">  such resources to the library and we will be developing innovative </h3> 
          <h3 class="mb-0">  ways to reach them to you.</h3>
        <!-- </div> -->
          <!-- <h3 class="m-4 mb-0">In line with this objective, access reliable and </h3>
          <h3>easy-to-read patient resource for free.</h3>

          <h3 class="mt-4 mb-0">These resources are created and reviewed by doctors and</h3>
          <h3>healthcare professionals.</h3>

          <h3 class="mt-4 mb-0">We will be adding more such resources to the library and we</h3>
          <h3>will be developing innovative ways to reach them to you.</h3> -->
        </div>
        <!-- FOR MOBILE  -->
        <div class="banner-sub-content d-block d-lg-none accesinformationheadingText">
          <h3 class="mb-0">One of our objectives is to</h3>
          <h3 class="mb-0">enhance the health literacy</h3>
          <h3>levels.</h3>

          <h3 class="mt-4 mb-0">In line with this objective,</h3>
          <h3 class="mb-0">acccess reliable and</h3>
          <h3 class="mb-0">easy-to-read patient resource</h3>
          <h3>for free.</h3>

          <h3 class="mt-4 mb-0">These resources are created</h3>
          <h3 class="mb-0">and reviewed by doctors and</h3>
          <h3>healthcare professionals.</h3>

          <h3 class="mt-4 mb-0">We will be adding</h3>
          <h3 class="mb-0">resources to the library and we</h3>
          <h3 class="mb-0">will be developing innovative</h3>
          <h3>ways to reach them to you.</h3>
        </div>
      </div>
    </section>
    <section class="banner-two2 img-banner-white">
      <div class="container-fluid text-center text-align">
        <!-- FOR DESKTOP  -->
        <div class="select-specility d-none d-lg-block">
          <h2>Select the first letter of the disease or condition.</h2>
        </div>
        <!-- FOR LAPTOP  -->
        <div class="select-specility d-block d-lg-none">
          <h2 class="mb-0">Select the first letter of</h2>
          <h2 class="mb-0">the disease or</h2>
          <h2>condition.</h2>
        </div>
        <!-- <div class="flex">
          <div class="drp-speciality">
            <select name="select" id="select" @click="getTopics()">
              <option value="city">Speciality</option>
              <option v-for="(doctor) in hcpDoctors" :value="doctor.practiceArea" :key = doctor>{{
                doctor.practiceArea
              }}
              </option>
            </select>
          </div>

          <div class="drp-topic">
            <select name="select" id="select">
              <option value="select">Topics</option>
              <option v-for="(doctor) in resourceUrl" :value="doctor.name" :key = doctor>{{
                doctor.name
              }}
              </option>
            </select>
          </div>

          <div class="go-btn">
            <button @click="clinicRes()">Go</button>
          </div>
        </div>  -->
        <div class="d-none d-lg-block">
          <div class="boxing-text p-3 mt-3">
            <div class="flex">
              <div class="letters-wrap" v-for="letter in letter" :key="letter">
                <div class="box-letter" @click="accessReliable(letter)">{{ letter }}</div>
              </div>
            </div>
            <div class="flex">
              <div class="letters-wrap" v-for="letter in letters" :key="letter">
                <div class="box-letter" @click="accessReliable(letter)">{{ letter }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-block d-lg-none">
        <div class="boxing-text p-3 mt-3">
          <div class="flex">
            <div class="letters-wrap" v-for="letter in AtoIletter" :key="letter">
              <div class="box-letter" @click="accessReliable(letter)">{{ letter }}</div>
            </div>
          </div>
          <div class="flex">
            <div class="letters-wrap" v-for="letter in JtoRletter" :key="letter">
              <div class="box-letter" @click="accessReliable(letter)">{{ letter }}</div>
            </div>
          </div>
          <div class="flex">
            <div class="letters-wrap" v-for="letter in StoZletter" :key="letter">
              <div class="box-letter" @click="accessReliable(letter)">{{ letter }}</div>
            </div>
          </div>
        </div>
      </div>

        <div class="row mt-3" style="margin: auto;">
          <div class="card card-box white-box">
            <div v-for="index in value" :key="index">
              <h3 class="resourceNames" @click="openResource(index)">{{ index.topic }}</h3>
            </div>
          </div>
        </div>

        <div v-if="ope">
          <div v-if="value.length === 0">
            <h2 @click="openResource(index)" style="margin-top: 5px; margin-left: 10px;color:rgb(239, 63, 63)">
              No topics available!
            </h2>
          </div>
        </div>

       
      </div>
    </section>
    <!-- <SignUpGeneral @isHide=signupPopupStatus($event) :dataValue="resourceData" v-if="issign"></SignUpGeneral> -->
  </div>
  </div>
  <MobileNavbar></MobileNavbar>
</template>
<script>
import MobileNavbar from "../common-mobile-footer.vue"
import axios from "axios"
export default {
  components: {
    MobileNavbar
  },
  data() {
    return {
      // issign: false,
      seen: true,
      // resourceUrl: [],
      value: [],
      valueData: [],
      dataValue: [],
      showTopic: false,
      showResource: false,
      // openRes: false,
      resourceData: {},
      ope: false

    }
  },
  created: function () {
    // this.getHCPDoctors()
    // this.getResourceUrl()
  },
  computed: {
    letter() {
      let letters = []
      for (let i = "A".charCodeAt(0); i <= "M".charCodeAt(0); i++) { letters.push(String.fromCharCode([i])) }
      return letters
    },
    letters() {
      let lettersData = []
      for (let i = "N".charCodeAt(0); i <= "Z".charCodeAt(0); i++) { lettersData.push(String.fromCharCode([i])) }
      return lettersData
    },
    AtoIletter() {
      let letters = []
      for (let i = "A".charCodeAt(0); i <= "I".charCodeAt(0); i++) { letters.push(String.fromCharCode([i])) }
      return letters
    },
    JtoRletter() {
      let lettersData = []
      for (let i = "J".charCodeAt(0); i <= "R".charCodeAt(0); i++) { lettersData.push(String.fromCharCode([i])) }
      return lettersData
    },
   StoZletter() {
      let lettersData = []
      for (let i = "S".charCodeAt(0); i <= "Z".charCodeAt(0); i++) { lettersData.push(String.fromCharCode([i])) }
      return lettersData
    },
  },
  methods: {
    requestConsultation() {
      window.scrollTo(0, 0);
      this.$router.push('/public/requestconsultation'); 
    },
    joinDoctorsCommunity() {
      window.scrollTo(0, 0);
      this.$router.push('/public/doctorcommunities'); 
    },
    joinPatientGroups() {
      window.scrollTo(0, 0);
      this.$router.push('/public/patientgroups'); 
    },
    searchClinic() {
      window.scrollTo(0, 0);
      this.$router.push('/public/searchclinic'); 
    },
    getTopics() {
      this.showTopic = true
    },
    getResource() {
      this.showResource = true
    },
    accessReliable(letter) {
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
      this.ope = true;
      axios
        .get(`${BASE_API_URL}/topic-disorder?q=${letter}`)
        .then((response) => {
          this.value = response.data;
        })
    },
    openResource(index) {
      localStorage.removeItem('topic')
      localStorage.removeItem('disorder')
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
      this.resourceData = index
      var myResource = [];
      var newvalues = [];
      var retrievedString = localStorage.getItem('topic');
      var retrievedArray = JSON.parse(retrievedString);
      if (retrievedArray === null) {
        myResource.push(index.topic)
      } else {
        newvalues.push(index.topic)
        myResource = [...retrievedArray, ...newvalues];
      }
      var uniqueResourcejsonString = [...new Set(myResource)];
      var myResourcejsonString = JSON.stringify(uniqueResourcejsonString);
      localStorage.setItem('topic', myResourcejsonString);
      var myDisorder = [];
      var disorderValues = [];
      var disorderString = localStorage.getItem('disorder');
      var disorderdArray = JSON.parse(disorderString);
      if (disorderdArray === null) {
        myDisorder.push(index.disorder)

      } else {
        disorderValues.push(index.disorder)
        myDisorder = [...disorderdArray, ...disorderValues];
      }
      var uniqueDisorder = [...new Set(myDisorder)];
      var myDisorderString = JSON.stringify(uniqueDisorder);
      localStorage.setItem('disorder', myDisorderString);
      // this.openRes = true;
      axios
        .get(`${BASE_API_URL}/res-url?q=${index.topic}`)
        .then((response) => {
          this.dataValue = response.data
          this.dataValues = [response.data[0]]
          const publicLibarayPayload={
            publcLibraryData: JSON.stringify(index),
            routeName:'accessreliableinformation',
            card:'public',
            publcLibraryId:JSON.parse(4)
          }
          window.scrollTo(0, 0);
          this.$router.push({ path: '/signup',query: publicLibarayPayload });
          // this.issign = true;
        })
    },
    // closeRes() {
    //   this.openRes = false;
    // },
    openSignup(resData) {
      this.resourceData = resData
      // this.issign = true;
    }
  }
}
</script>
<style>
.resourceNames {
  margin-top: 5px;
  margin-left: 10px;
}

.banner-sub-content {
  margin-top: 20px;
}

.drp-speciality select {
  background-color: #fff;
  color: #4d4d4d;
  border-color: #999999;
  width: 194px;
  height: 35px;
  border-radius: 8px;
  font-size: 21px;
}

/* .drp-topic select {
  background-color: #fff;
  color: #4d4d4d;
  border-color: #999999;
  width: 194px;
  height: 35px;
  border-radius: 8px;
  font-size: 21px;
  margin-left: 44px;
  margin-right: 44px;
} */

.go-btn button {
  color: #fff;
  background-color: #34989f;
  outline: none;
  border: none;
  font-weight: bold;
  font-size: larger;
  border-radius: 12px;
  width: 72px;
  height: 37px;
  margin-left: 30px;
}

.flex {
  display: flex;
  justify-content: center;
  padding-top: 10px;
}

.card.card-box.white-box {
  width: auto;
  margin: auto;
  margin-bottom: 30px;
  border-width: thin;
  box-shadow: none;
  border-color: #34989f;
  text-align: start;
  cursor: pointer;

}

.card.card-box.white-box1 {
  width: 45% !important;
  margin: auto;
  margin-bottom: 30px;
  /* height: 100vh; */
  height: 100%;
  border-color: #34989f;
  text-align: start;
  cursor: pointer;
}

select.form-control.drp {
  width: 30%;
  font-size: 17px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
}


.heading-information h1 {
  text-align: center;
  color: #000000;
  font-weight: bold;
}

.heading-information {
  margin-top: 20px;
}

/* .reliable-information {
  display: flex;
  justify-content: center;
}

.reliable-information-para {
  font-size: 30px;
  color: #4d4d4d;
  width: 47%;
} */

.select-specility {
  color: #000;
  margin-top: 3rem;
  text-align: center;
}

nav.three-nav1 {
  float: right;
  position: fixed;
  right: -3px;
  background: transparent;
}

nav.three-nav1:hover {
  transform: perspective(400px) rotateY(1deg);
}

nav.three-nav1 ul.three-nav1 {
  text-align: center;
  margin-top: 30px;
}

@media screen and (max-width:992px) {
  nav.three-nav1 ul.three-nav1 {
    text-align: center;
    margin-top: 0px
  }
}

nav.three-nav1 ul.three-nav1 li.three-nav1 {
  position: relative;
  width: 71px;
  cursor: pointer;
  background: cadetblue;
  text-transform: uppercase;
  transition: all .4s ease-out;
  list-style: none;
}

nav.three-nav1 ul.three-nav1 li:after {
  position: absolute;
  background: white;
  color: crimson;
  top: 0;
  left: 0px;
  width: 70px;
  height: 100%;
  opacity: .5;
  transform: perspective(400px) rotateY(90deg);
  transform-origin: 0 100%;
  transition: all .4s ease-out;
}

nav.three-nav1 ul.three-nav1 li:nth-child(1):after {
  line-height: 88px;
}

nav.three-nav1 ul.three-nav1 li:nth-child(2):after {
  line-height: 88px;
}

nav.three-nav1 ul.three-nav1 li:nth-child(3):after {
  line-height: 85px;
}

nav.three-nav1 ul.three-nav1 li:nth-child(4):after {
  line-height: 70px;
}

nav.three-nav1 ul.three-nav1 li:nth-child(5):after {
  line-height: 85px;
}

nav.three-nav1 ul.three-nav1 li:hover {
  transform: translateX(0px);
  background-color: #fff;
}

nav.three-nav1 ul.three-nav1 li:hover:after {
  opacity: 1;
  transform: perspective(400px) rotateY(90deg) scale(1);
}


nav.three-nav1 ul.three-nav1 li>div {
  display: inline-block;
  padding: 25px 0;
  background: transparent;
}

nav.three-nav1 ul.three-nav1 li.three-nav1 div {
  position: relative;
}


li.navbar-nav-list.hidden-md.hidden-lg.dropdown.xs-design.open-side-menu1 {
  list-style: none;
  position: relative;
  left: 35px;
  bottom: 20px;
}

section.banner-two2.img.banner {
  margin-top: 4.5rem;
  background-repeat: no-repeat;
  background-image: url("~@/assets/images/cards-banner7.png");
  background-size: 3500px 593px;
  /* transform: rotate(90deg);  */
  padding: 30px 0px 50px 0px;
}

section.banner-two2.img-banner-white {
  background-color: #fff;
  background-repeat: no-repeat;
}

.navbar-light .navbar-nav .nav-link {
  color: #00979e;
}



.boxing-text {
  width: 35%;
  margin-left: auto;
  margin-right: auto;
  /* border: 1px solid #00979e; */
}

.box-letter {
  border: 1.5px solid #00979e;
  width: 30px;
  height: 30px;
  /* margin: 10px; */
  margin-left: 5px;
  margin-right: 5px;
  color: gray;
  /* text-decoration: underline; */
  cursor: pointer;

}
@media screen and (max-width:991px) {
  .navbarmobileFix{
    margin-bottom: 7rem;
  }
  section.banner-two2.img.banner {
    margin-top: 0rem;
  }

  .heading-information h1 {
    font-size: 33px;
  }

  .accesinformationheadingText h3 {
    font-size: 22px;
  }

  .select-specility {
    margin-top: 2rem;
  }

  .select-specility h2 {
    font-size: 28px;
  }

  .resourceNames {
    font-size: 25px;
  }
}

@media screen and (max-width: 576px) {

  .heading-information h1 {
    font-size: 25px;
  }

  .accesinformationheadingText h3 {
    font-size: 17px;
  }

  .select-specility {
    margin-top: 1.5rem;
  }

  .select-specility h2 {
    font-size: 21px;
  }

  .resourceNames {
    font-size: 18px;
    margin-left: 0px;
  }

  section.banner-two2.img.banner {
    padding: 10px 0px 24px 0px;
  }
}


@media screen and (max-width:550px) {
  .banner-sub-content {
    margin-top: 10px;
  }

  section.banner-two2.img.banner {
    padding: 10px 0px 24px 0px;
  }

}
@media screen and (max-width:420px) {
  .box-letter {
    font-size: 15px;
    width: 23px;
    height: 23px;
  }
}
</style>